import '../stylesheets/home';
import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import getConfig from './_component/slick';

$(function () {
  // スライドバナー
  $('.slick-wrapper.pc .slick.slide-banners').each(function () {
    $(this).slick(getConfig($(this)));
  });
  $('.slick-wrapper.sp .slick.slide-banners').each(function () {
    $(this).slick(Object.assign(getConfig($(this)), {
      variableWidth: true,
      centerMode: true,
      centerPadding: '12.8%',
    }));
  });

  // ピックアップ
  $('.stock-group .slick.stocks').each(function () {
    $(this).slick({
      infinite: false,
      variableWidth: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: true,
      prevArrow: $(this).siblings('.prev'),
      nextArrow: $(this).siblings('.next'),
    });
  });
});
