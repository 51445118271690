function getConfig($this) {
  const config = {
    arrows: true,
    autoplay: true,
    dots: true,
    autoplaySpeed: 5000,
  };
  return Object.assign(config, {
    prevArrow: $this.siblings('.prev'),
    nextArrow: $this.siblings('.next'),
  });
}

export default getConfig;